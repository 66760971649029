<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">物资调拨单管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="新增调拨单" name="a"><Save_index v-if="a" /></el-tab-pane>
          <el-tab-pane label="调拨单流程查询" name="e"><Admin_lcget v-if="e" /></el-tab-pane>
        <el-tab-pane label="回退调拨单" name="b"  ><Update_index v-if="b" /></el-tab-pane>
        <el-tab-pane v-if="this.GET_BDST === '1'" label="审批人员设置" name="c"  ><Admin_index v-if="c" /></el-tab-pane>
        <el-tab-pane label="调拨单导出" name="d"  ><Dbd_leadout v-if="d" /></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import Header from "@/views/header";
import Save_index from "@/views/material/dbd/save/save_index";
import Update_index from "@/views/material/dbd/update/update_index";
import {mapGetters} from "vuex";
import Admin_index from "@/views/material/dbd/admin/admin_index.vue";
import Dbd_leadout from "@/views/material/dbd/admin/dbd_leadout.vue";
import Admin_lcget from "@/views/material/dbd/admin/admin_lcget.vue";

export default {
  name: "index",
  components: {Header, Save_index, Update_index, Admin_index, Dbd_leadout, Admin_lcget},
  inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
  data() {
    return {
        activeName: 'a',
        a: true,
        b: false,
        c: false,
        d: false,
        e: false
    }
  },
  created() {
	  if (!this.$route.params.key){
		  this.$router.push("/manage")
	  }
	  if (sessionStorage.getItem('dbd_index_tab')){
		  this.activeName = sessionStorage.getItem('dbd_index_tab');
		  if (this.activeName === 'a'){
			  this.a = true
			  this.b = false
			  this.c = false
			  this.d = false
			  this.e = false
		  }
		  if (this.activeName === 'b'){
			  this.b = true
			  this.a = false
			  this.c = false
			  this.d = false
			  this.e = false
		  }
		  if (this.activeName === 'c'){
			  this.b = false
			  this.a = false
			  this.c = true
			  this.d = false
			  this.e = false
		  }
		  if (this.activeName === 'd'){
			  this.b = false
			  this.a = false
			  this.c = false
			  this.d = true
			  this.e = false
		  }
		  if (this.activeName === 'e'){
			  this.b = false
			  this.a = false
			  this.c = false
			  this.d = false
			  this.e = true
		  }
	  }
  },
  methods: {
    handleClick(tab, event){
	    sessionStorage.setItem('dbd_index_tab', tab.props.name)
      if (tab.props.name === 'a'){
        this.a = true
        this.b = false
        this.c = false
        this.d = false
        this.e = false
      }
      if (tab.props.name === 'b'){
          this.b = true
          this.a = false
          this.c = false
          this.d = false
          this.e = false
      }
        if (tab.props.name === 'c'){
          this.b = false
          this.a = false
          this.c = true
          this.d = false
          this.e = false
      }
      if (tab.props.name === 'd'){
          this.b = false
          this.a = false
          this.c = false
          this.d = true
          this.e = false
      }
        if (tab.props.name === 'e'){
            this.b = false
            this.a = false
            this.c = false
            this.d = false
            this.e = true
        }
    },
	  reall(){
		  sessionStorage.removeItem('scheme_index_tab');
	  }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}

#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>
