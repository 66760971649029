<template>
  <div>
    <div>
      <el-table :data="back_list" style="width: 100%">
        <el-table-column prop="dbdNum" label="调拨单编号" width="180" />
          <el-table-column prop="dbdType" label="材料类别" width="180" />
          <el-table-column prop="drBdst" label="调入工区" width="180" />
          <el-table-column prop="dcBdst" label="调出工区" width="180" />
          <el-table-column prop="dbdTime" label="调出日期" width="180" />
        <el-table-column prop="backReason" label="退回原因" width="180" />
        <el-table-column prop="a" label="操作" >
          <template #default="scope">
            <el-button
                link
                type="primary"
                size="small"
                @click.prevent="get_detail(scope.row)"> 查看详情 </el-button>
            <el-button
                link
                type="danger"
                size="small"
                @click.prevent="remove_dbd(scope.row)"> 接受退回并重新提交 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-dialog
          v-model="dialogDbdDetail"
          title="调拨单详情"
          width="80%"
      >
        <!--  调拨单主内容-->
        <div>
          <div>
            材料类型：{{ dbd_main.dbdType }}
          </div>
          <div>
            调拨编号：{{ dbd_main.dbdNum }}
          </div>
          <div>
            调拨时间：{{ dbd_main.dbdTime }}
          </div>
          <div>
            调出工区：{{ dbd_main.dcBdst }}
          </div>
          <div>
            调入工区：{{ dbd_main.drBdst }}
          </div>
        </div>
        <el-divider />
        <!--  调拨单主要数据-->
	      <div v-for="(index, item) in material_list" :key="index" style="width: 100%">
		      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;">
			      <div>
				      <el-tag>材&nbsp;&nbsp;料&nbsp;&nbsp;名&nbsp;&nbsp;&nbsp;称&nbsp;</el-tag>
				      <el-input v-model="material_name[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入材料名称"/>
			      </div>
			      <div>
				      <el-tag>规&nbsp;&nbsp;格&nbsp;&nbsp;型&nbsp;&nbsp;&nbsp;号&nbsp;</el-tag>
				      <el-input v-model="material_model[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入规格型号"/>
			      </div>
			      <div>
				      <el-tag>单位</el-tag>
				      <el-select v-model="material_unit[index]" class="m-2" placeholder="请选择单位" size="small" style="width: 60%;margin-left: 1%">
					      <el-option
						      v-for="item in units"
						      :key="item.value"
						      :label="item.label"
						      :value="item.value"
					      />
				      </el-select>
				      <!--          <el-input v-model="material_unit[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入单位"/>-->
			      </div>
			      <div>
				      <el-tag>数量</el-tag>
				      <el-input v-model="material_num[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入数量"/>
			      </div>
		      </div>
		      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr;margin-top: 1%">
			      <div>
				      <el-tag>不含税单价(元)</el-tag>
				      <el-input v-model="material_no_taxrate[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入不含税单价(元)"/>
			      </div>
			      <div>
				      <el-tag>不含税金额(元)</el-tag>
				      <el-input v-model="material_money[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入不含税金额(元)"/>
			      </div>
			      <div>
				      <el-tag>备注</el-tag>
				      <el-input v-model="remarks[index]" style="width: 60%;margin-left: 1%" size="small" placeholder="请输入备注"/>
			      </div>
			      <div>
				      <el-button v-if="index === material_list.length - 1" size="small" type="danger" style="width: 73%" @click="add_list">新增一行</el-button>
			      </div>
		      </div>
		      <el-divider />
	      </div>
        <div>
          合计: {{ all_money }}元
        </div>
        <el-divider />
        <template #footer>
          <span class="dialog-footer">
	          <el-button type="danger" @click="updataDbd">重新提交</el-button>
            <el-button @click="dialogDbdDetail = false">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import {setNowDate, setNowTimes} from "@/utils/time";

export default {
  name: "index",
  inject: ['reload'],
  computed: {
    ...mapGetters(['GET_USERID'])
  },
  data() {
    return {
      back_list: [],
      bd_main: [],
      dbd_detail_list: [],
      all_money: '',
      dialogDbdDetail: false,
      material_list: [0],
      material_name: [],
      material_model: [],
      material_unit: [],
      material_num: [],
      material_no_taxrate: [],
      material_money: [],
      remarks: [],
      created_time: [],
        units: [
            {
                value: '吨',
                label: '吨',
            },
            {
                value: 'm',
                label: 'm',
            },
            {
                value: 'm²',
                label: 'm²',
            },
            {
                value: 'm³',
                label: 'm³',
            },
            {
                value: '个',
                label: '个',
            },
            {
                value: '卷',
                label: '卷',
            },
            {
                value: '根',
                label: '根',
            },
            {
                value: '件',
                label: '件',
            },
            {
                value: '套',
                label: '套',
            }
        ],
	    main_id: ''
    }
  },
  created() {
    this.get_back()
  },
  methods: {
	  add_list(){
		  this.material_list.push(this.material_list[this.material_list.length - 1] + 1)
		  this.remarks[this.material_list[this.material_list.length - 1]] = ' '
	  },
    get_back(){
      this.axios.post('/materialDbdMain/getBackDbd', (response) => {
        this.back_list = response.obj
      },{
        user_id: this.GET_USERID
      })
    },
    get_detail(item){
			this.main_id = item.id
      this.axios.get("/materialDbdMain/getDetailByMainId", (response) => {
        this.all_money = response.message
        this.dbd_main = response.obj2
        this.dbd_detail_list = response.obj3
        this.dialogDbdDetail = true
	      for (let i = 0; i < this.dbd_detail_list.length; i++){
		      // this.material_list.push(this.material_list[this.material_list.length - 1] + 1)
		      // this.remarks[this.material_list[this.material_list.length - 1]] = ' '
		      this.material_name.push(this.dbd_detail_list[i].materialName)
			    this.material_model.push(this.dbd_detail_list[i].materialModel)
			    this.material_unit.push(this.dbd_detail_list[i].materialUnit)
			    this.material_num.push(this.dbd_detail_list[i].materialNum)
			    this.material_no_taxrate.push(this.dbd_detail_list[i].materialNoTaxrate)
			    this.material_money.push(this.dbd_detail_list[i].materialMoney)
		      if(this.dbd_detail_list[i].remarks !== ''){
			      this.remarks.push(this.dbd_detail_list[i].remarks)
		      }else {
			      this.remarks.push(' ')
		      }
			    this.created_time.push(this.dbd_detail_list[i].createdTime)
	      }
      },{
        dbd_id: item.id
      })
    },
    remove_dbd(item){
      this.axios.get("/materialDbdMain/removeDbdById", (response) => {
        if (response.obj){
          ElMessage({
            message: '接受成功，请重新提交调拨单!',
            type: 'success'
          })
          this.reload()
        }
      },{
        dbd_id: item.id
      })
    },
	  updataDbd(){
		  this.axios.post('/materialDbd/updateDbd', (response) => {
			  if (response.obj){
				  ElMessage({
					  message: '修改成功',
					  type: 'success'
				  })
				  this.reload()
				  this.axios.get('/materialDbdMain/getLcFlagById', (response1) => {
					  for (let i = 0; i < response1.obj.length; i++){
						  this.axios.post('/wxSendMessage/sendDbdSp', (response2) => {

						  },{
							  user_id: response1.obj[i]
						  })
					  }
				  },{
					  id: response.obj2
				  })
			  }
		  },{
				main_id: this.main_id,
			  material_name: this.material_name,
			  material_model: this.material_model,
			  material_unit: this.material_unit,
			  material_num: this.material_num,
			  material_no_taxrate: this.material_no_taxrate,
			  material_money: this.material_money,
			  remarks: this.remarks,
			  created_time: setNowTimes(new Date()),
			  time: setNowDate(new Date())
		  })
	  }
  }
}
</script>

<style scoped>

</style>
