<template>
    <div>
        <div>
            <el-select v-model="end" class="m-2" placeholder="Select" size="small" @change="getLc">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                />
            </el-select>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="dbdNum" label="调拨单编号" width="180" />
                <el-table-column prop="dbdType" label="材料类别" width="180" />
                <el-table-column prop="drBdst" label="调入工区" width="180" />
                <el-table-column prop="dcBdst" label="调出工区" width="180" />
                <el-table-column prop="dbdTime" label="调出日期" width="180" />
                <el-table-column prop="dcZg" label="当前流程节点" width="180" />
                <el-table-column prop="a" label="发送审批短信" width="180">
                    <template #default="scope">
                        <el-button type="primary" @click="sendSms(scope.row)"> 发送短信 </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {ElMessage} from "element-plus";

export default {
    name: "admin_index",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data(){
        return{
            tableData: [],
            end: '0',
            options: [
                {
                    value: '0',
                    label: '未完成',
                },
                {
                    value: '1',
                    label: '已完成',
                }
            ]
        }
    },
    created() {
        this.getLc()
    },
    methods:{
        getLc(){
            this.axios.get('/materialDbdMain/getSpLc', (response) => {
                this.tableData = response.obj
            },{
                bdst: this.GET_BDST,
                flag: this.end,
	              material_equiment: 0
            })
        },
        sendSms(row){
            this.axios.get('/materialDbdMain/snedDbdSp', (response) => {
                console.log(response.obj)
                for (let i = 0; i < response.obj.length; i++){
                    console.log(response.obj[i])
                    this.axios.get('/userinfo/getUserByIdSendSms', (response2) => {
                        if (response2){
                            ElMessage({
                                message: '审批短信发送成功!',
                                type: 'success'
                            })
                        }else {
                            ElMessage.error('审批短信发送失败，请联系科信部!')
                        }
                    },{
                        user_id: response.obj[i]
                    })
                }
            },{
                dbd_id: row.id
            })
        }
    }
}

</script>
<style scoped>

</style>