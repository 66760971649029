<template>
    <div>
        <!--	调拨单列表-->
        <div>
            <el-table :data="dbd_list" style="width: 100%">
                <el-table-column prop="dbdType" label="调拨材料类型" width="180"/>
                <el-table-column prop="dcBdst" label="调出工区" width="180"/>
                <el-table-column prop="drBdst" label="调入工区" width="180"/>
                <el-table-column prop="dbdTime" label="调拨时间" width="180"/>
                <el-table-column prop="dbdNum" label="调拨单编号" width="180"/>
	              <el-table-column prop="all_money" label="不含税金额" width="180"/>
	              <el-table-column prop="checkNum" label="查看次数" width="180"/>
                <el-table-column prop="a" label="操作">
                    <template #default="scope">
                        <el-button
                                style="margin-right: 3%"
                                link
                                type="primary"
                                size="small"
                                @click.prevent="get_detail(scope.row)"> 查看详情并打印
                        </el-button>

                        <!--<a :href="dbd_leadout">-->
                        <!--    <el-button-->
                        <!--            link-->
                        <!--            type="danger"-->
                        <!--            size="small"-->
                        <!--            @mouseover="get_main_id(scope.row)"> 导出 </el-button>-->
                        <!--</a>-->

                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--			调拨单详情-->
        <div>
            <el-dialog
                    v-model="dialogDbdDetail"
                    title=""
                    width="80%"
                    @close="add_check_num()"
            >
                <!--  调拨单主内容-->
                <div id="myPrint5">
                    <div  style="margin-left: 10%">
                        <div>
                            <div style="width: 100%;">
                                <div style="text-align: center;font-size: 2rem;">
                                    材料调拨单
                                </div>
                                <div style="display: flex;justify-content: space-between">
                                    <div style="margin-right: 60%">
                                        类别:{{ dbd_main.dbdType }}
                                    </div>
                                    <div style="margin-right: 5%">
                                        编号:{{ dbd_main.dbdNum }}   时间:{{ dbd_main.dbdTime }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--  调拨单主要数据-->
                        <div style="margin-top: 1%;width: 100%;">
                            <div style="display: flex">
                                <div style="width: 5%;border: 1px solid black;text-align: center;height: 5vh;line-height: 4vh;font-size: 1rem;font-weight: bolder;">序号</div>
                                <div style="width: 20%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">材料名称</div>
                                <div style="width: 15%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">规格型号</div>
                                <div style="width: 5%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">单位</div>
                                <div style="width: 10%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">数量</div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">不含税单价(元)</div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">不含税金额(元)</div>
                                <div style="width: 23.3%;border: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;">备注</div>
                            </div>
                        </div>
                        <div v-for="(item, index) in dbd_detail_list" :key="index">
                            <div v-if="index % 17 === 0 && index !== 0" style="display: flex;width: 100%">
                                <div style="width: 5%;border: 1px solid black;text-align: center;height: 5vh;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 20%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 15%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 5%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 10%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                                <div style="width: 23.3%;border: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
                            </div>
                            <div style="display: flex;width: 100%">
                                <div style="width: 5%;border-bottom: 1px solid black;border-left: 1px solid black;border-right: 1px solid black;text-align: center;height: 5vh;line-height: 4vh;font-size: 1rem;">{{index + 1}}</div>
                                <div style="width: 20%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialName}}</div>
                                <div style="width: 15%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialModel}}</div>
                                <div style="width: 5%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialUnit}}</div>
                                <div style="width: 10%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialNum}}</div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialNoTaxrate}}</div>
                                <div style="width: 13%;border-bottom: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{item.materialMoney}}</div>
                                <div style="width: 23.3%;border-bottom: 1px solid black;border-left: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{ item.remarks }}</div>
                            </div>
                        </div>
                        <div style="display:flex;width: 100%">
                            <div style="width: 5%;border-bottom: 1px solid black;border-left: 1px solid black;border-right: 1px solid black;text-align: center;height: 5vh;line-height: 4vh;font-size: 1rem;"></div>
                            <div style="width: 20%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">合计</div>
                            <div style="width: 15%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;"></div>
                            <div style="width: 5%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;"></div>
                            <div style="width: 10%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{ qz_gz[0] }}</div>
                            <div style="width: 13%;border-bottom: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;"></div>
                            <div style="width: 13%;border-bottom: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{ all_money }}</div>
                            <div style="width: 23.3%;border-bottom: 1px solid black;border-left: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;"></div>

                        </div>
	                      <div style="display:flex;width: 100%">
		                      <div style="width: 5%;border: 1px solid black;text-align: center;height: 5vh;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 20%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 15%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 5%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 10%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;border-right: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
		                      <div style="width: 23.3%;border: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;font-weight: bolder;"></div>
	                      </div>
                        <!-- 签名、公章等信息-->
                        <div style="width: 100%;">
                            <!-- 调出工区 -->
                            <div style="display: flex">
                                <!-- 调出单位 -->
                                <div style="width: 36%;position:relative">
                                    <div style="display: flex">
                                        <div style="width: 27%;border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">调出单位</div>
                                        <div style="width: 73%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4.9vh;font-size: 1rem;">{{ dbd_main.dcBdst }}</div>
                                    </div>
                                    <div style="display: flex">
                                        <!-- 调出单位 -->
                                        <div style="width: 27.2%;border-bottom: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;border-left: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">调出单位主管</div>
                                        <div style="width: 73%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">
                                            <img style="width: 200px;height: 100px" :src="qz_gz[3]" alt="#"/>
                                        </div>
                                    </div>
                                    <!-- 调出单位公章  -->
                                    <div  style="position: absolute;bottom: 20%;left: 45%">
                                        <img style="height: 180px" :src="qz_gz[4]" alt="#"/>
                                    </div>
                                </div>

                                <!-- 调出物资部长 -->
                                <div style="width: 10%;border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;padding-top: 3%">调出物资部长</div>
                                <div style="width: 13%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh">
                                    <img style="width: 150px;height: 150px;padding-top: 30%" :src="qz_gz[2]" alt="#"/>
                                </div>

                                <div style="width: 35%;position:relative">
                                    <div style="display: flex">
                                        <!-- 调入单位 -->
                                        <div style="width: 25%;border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">调入单位</div>
                                        <div style="width: 75%;border-bottom: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">{{ dbd_main.drBdst }}</div>
                                    </div>
                                    <div style="display: flex">
                                        <!-- 调入单位主管 -->
                                        <div style="width: 25%;border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;">调入单位主管</div>
                                        <div style="width: 75%;border-bottom: 1px solid black;text-align: center;;font-size: 1rem;line-height: 4vh">
                                            <img style="width: 200px;height: 120px" :src="qz_gz[6]" alt="#"/>
                                        </div>
                                    </div>
                                    <!-- 调入单位公章  -->
                                    <div style="position:absolute;bottom: 20%;left: 45%">
                                        <img style="height: 180px" :src="qz_gz[7]" alt="#"/>
                                    </div>
                                </div>
                                <!-- 调入物资部长 -->
                                <div style="width: 10%;border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;border-top: 1px solid black;text-align: center;line-height: 4vh;font-size: 1rem;padding-top: 3%">调入物资部长</div>
                                <div style="width: 23%;border-bottom: 1px solid black;border-right: 1px solid black;border-top: 1px solid black;text-align: center;font-size: 1rem;line-height: 2vh">
                                    <img style="width: 200px;height: 150px;padding-top: 30%" :src="qz_gz[5]" alt="#"/>
                                </div>
                            </div>
                        </div>
                        <!-- 项目公司领导签字-->
                        <div style="display: flex;justify-content: space-between">
                            <div style="display: flex">
                                <p style="margin-top: 25%;font-size: 1rem">分管领导 :</p>
                                <img style="width: 150px;padding-top: 20%" :src="qz_gz[9]"  alt="#"/>
                            </div>
                            <div style="display: flex">
                                <p style="margin-top: 20%;font-size: 1rem">设备物资部 :</p>
                                <img style="width: 100px;height: 80px;padding-top: 10%;" :src="qz_gz[8]"  alt="#"/>
                            </div>
                        </div>
                    </div>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="primary" @click="tets">预览并打印</el-button>
                        <el-button @click="dialogDbdDetail = false">取消</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </div>

</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
    name: "dbd_leadout",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            dbd_list: [],
            bd_main: [],
            dbd_detail_list: [],
            all_money: '',
            dialogDbdDetail: false,
            dbd_leadout: '',
            qz_gz: []
        }
    },
    created() {
        this.get_bdb_bdst()
    },
    methods: {
        // 获取调拨单
        get_bdb_bdst() {
            this.axios.get('/materialDbdMain/getDbdByBdst', (response) => {
                this.dbd_list = response.obj
	              for (let i = 0; i < this.dbd_list.length; i++){
		              this.axios.get("/materialDbdMain/getDetailByMainId", (response) => {
			              this.dbd_list[i].all_money = response.message
		              }, {
			              dbd_id: this.dbd_list[i].id,
			              material_equiment: 0
		              })
	              }
            }, {
                bdst: this.GET_BDST,
	            material_equiment: 0
            })
        },
        get_detail(item) {
            this.axios.get("/materialDbdMain/getDetailByMainId", (response) => {
                this.all_money = response.message
                this.qz_gz = response.obj
                this.dbd_main = response.obj2
                this.dbd_detail_list = response.obj3
                this.dialogDbdDetail = true
	              this.axios.post('/materialDbdMain/addCheckNum', (response1) => {
									if (response1.obj){
										item.checkNum++
									}
	              },{
									dbd_id: item.id
	              })
            }, {
                dbd_id: item.id,
	            material_equiment: 0
            })
        },
        get_main_id(item) {
            this.dbd_leadout = 'https://www.gjegm.ltd/czsczq/materialDbdMain/leadoutDbdById?id=' + item.id
        },
        tets() {
            const divEl = document.getElementById('myPrint5')
            VXETable.print({
                sheetName: '',
                content: divEl.innerHTML
            })
        },
	    add_check_num(){

	    }
    }
}
</script>

<style scoped>

</style>